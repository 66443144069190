<template>
  <div>
    <div v-if="form.submitStatus === 'OK'" class="form__success">
      <h1><i class="fal fa-check-circle" /></h1>
      <h2>Level Updated</h2>
    </div>
    <form
      v-if="form.submitStatus !== 'OK'"
      class="form"
      @submit.prevent="submit"
    >
      <Field label="Name" type="black">
        <TextInput
          v-model.trim="$v.form.name.$model"
          placeholder="Level name"
        />
      </Field>

      <Field label="Level Label" type="black">
        <TextInput
          v-model.trim="$v.form.levelLabel.$model"
          placeholder="Level Label"
        />
      </Field>

      <Field label="Card Image" type="black">
        <FileInput v-model.trim="$v.form.imagePath.$model" />
      </Field>

      <Field label="Header Image" type="black">
        <FileInput v-model.trim="$v.form.headerImagePath.$model" />
      </Field>

      <Field label="Next Level Id" type="black">
        <TextInput
          v-model.trim="$v.form.nextLevelId.$model"
          placeholder="Description"
        />
      </Field>

      <Field label="Order" type="black">
        <TextInput v-model.trim="$v.form.order.$model" placeholder="300" />
      </Field>

      <Field label="Points" type="black">
        <TextInput v-model.trim="$v.form.points.$model" placeholder="100" />
      </Field>

      <Field>
        <Button :on-click="cancel">
          Cancel
        </Button>
        <ButtonSubmit type="secondary">
          Submit
        </ButtonSubmit>
      </Field>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  props: {
    levelId: {
      type: String,
      default: null
    },
    journeyId: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      id: null,
      form: {
        name: '',
        levelLabel: '',
        nextLevelId: '',
        imageUrlPath: null,
        headerImagePath: null,
        imagePath: null,
        order: '',
        points: '',
        submitStatus: null
      }
    }
  },
  computed: {
    ...mapState({
      level: state => state.level.level
    }),
    ...mapGetters({})
  },
  validations: {
    form: {
      name: {
        required
      },
      levelLabel: {},
      nextLevelId: {},
      headerImagePath: {},
      imagePath: {},
      order: {},
      points: {},
      image: {}
    }
  },
  watch: {
    levelId: {
      immediate: true,
      handler(value) {
        this.id = value

        console.log('LevelForm', this.id, this.journeyId)

        if (this.id) {
          this.$store.dispatch('level/openDBChannel', {
            pathVariables: {
              levelId: this.id,
              journeyId: this.journeyId
            }
          })
        } else {
          this.$store.dispatch('level/closeDBChannel', {
            clearModule: true
          })
        }
      }
    },
    level: {
      immediate: true,
      handler(value) {
        console.log('value', value)
        if (this.level) {
          console.log('LEVEL', this.level)
          this.form.id = this.level.id
          this.form.name = this.level.name
          this.form.levelLabel = this.level.levelLabel
          this.form.nextLevelId = this.level.nextLevelId
          this.form.imagePath = this.level.cardImagePath
          this.form.headerImagePath = this.level.headerImagePath
          this.form.order = this.level.order
          this.form.points = this.level.points
        }
      }
    }
  },
  methods: {
    ...mapActions({
      addLevel: 'levels/addLevel',
      editLevel: 'level/editLevel'
    }),
    cancel() {
      console.log('cancel')
      this.$emit('onSubmit')
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.form.submitStatus = 'ERROR'
      } else {
        this.form.submitStatus = 'OK'
        this.$emit('onSubmit')

        switch (this.mode) {
          case 'edit':
            console.log('edit', this.form)
            this.editLevel(this.form)
            break
          case 'duplicate':
            delete this.form.id
            console.log('duplicate', this.form)
            this.addLevel(this.form)
            break
          default:
            delete this.form.id
            console.log('add', this.form)
            this.addLevel(this.form)
            break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form__success {
  padding: $padding 0;
  text-align: center;

  h1 {
    color: $color-secondary;
    font-size: 4.8rem;
  }

  h2 {
    color: $color-primary;
    font-size: 2.4rem;
    font-weight: 400;
  }
}
</style>
