<template>
  <Content>
    <FlexContainer>
      <FlexItem v-if="false" size="25%" padding="normal">
        <Card>Filters</Card>
      </FlexItem>
      <FlexItem padding="normal" :grow="true">
        <Card>
          <h1>Add Level</h1>
          <LevelForm :level-id="id" />
        </Card>
      </FlexItem>
    </FlexContainer>
  </Content>
</template>

<script>
import LevelForm from '@/components/admin/LevelForm'

export default {
  components: {
    LevelForm
  },
  data: () => {
    return {
      id: null
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
    }
  }
}
</script>

<style lang="scss" scoped></style>
